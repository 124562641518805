<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Sepelio - Ver</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" autocomplete="off" @submit.prevent="Validate">
              <b-row>
              

                <b-col md="6">
                  <b-form-group>
                    <label>Socio: </label>
                    <v-select disabled placeholder="Seleccione un socio" class="w-100" :filterable="false" label="full_name" v-model="partner" @search="SearchClients" :options="partners"></v-select>
                    <small v-if="errors.id_partner" class="form-text text-danger" >Seleccione un socio</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Nro Sepelio:">
                    <b-form-input class="text-center" disabled type="text" v-model="burial.number"></b-form-input>
                    <small v-if="errors.number"  class="form-text text-danger" >Ingrese un numero</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha Solicitud:">
                    <b-form-input type="date" disabled class="text-center" v-model="burial.application_date"></b-form-input>
                    <small v-if="errors.application_date"  class="form-text text-danger" >Selecciona una fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha Desembolso:">
                    <b-form-input type="date" disabled class="text-center" v-model="burial.disbursement_date"></b-form-input>
                    <small v-if="errors.disbursement_date"  class="form-text text-danger" >Selecciona una fecha</small>
                  </b-form-group>
                </b-col>

                

                <b-col md="6">
                  <b-form-group>
                    <label>Familiar y/o Beneficiario::</label>
                     <v-select disabled placeholder="Seleccione un familiar y/o beneficiario" label="text" class="w-100"  v-model="partner_family" :options="partners_family"></v-select>
                    <small v-if="errors.id_partner_family" class="form-text text-danger" >Seleccione un beneficiario</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Tipo Beneficiario:">
                    <b-form-input disabled class="text-left" :value="partner_family == null ? '': partner_family.type_beneficiary"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Tipo Sepelio:">
                    <b-form-select disabled @change="BurialType" v-model="burial.burial_type" :options="burial_type"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Monto:">
                    <b-form-input disabled type="number" step="any" class="text-right" v-model="burial.total"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="8">
                  <b-form-group>
                    <label>Desembolso: </label>
                    <v-select disabled placeholder="Seleccione un desembolso" class="w-100" :filterable="false" label="full_name" v-model="check" @search="SearchChecks" :options="checks"></v-select>
                    <small v-if="errors.id_check" class="form-text text-danger" >Seleccione un desembolso</small>
                  </b-form-group>
                </b-col>
                
               <b-col md="4">
                  <b-form-group label="Cambiar Doc. Adjuntos:">
                     <b-form-file
                      accept="application/pdf"
                      v-model="file"
                      :state="Boolean(file)"
                      disabled
                      @change="onFileChange"
                      placeholder="Selecciona un archivo"
                      drop-placeholder="Arrastre el archivo aqui"
                    ></b-form-file>
                    <small v-if="errors.file"  class="form-text text-danger" >Seleccione un documento</small>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="Observación:">
                    <b-form-textarea disabled type="text" v-model="burial.observation"></b-form-textarea>
                  </b-form-group>
                </b-col>

                

                
                

       
                <b-col md="4"></b-col>
                <b-col md="2">
                  <b-button type="button" :disabled="burial.file.length == 0" @click="OpenFile" class="form-control" variant="warning" ><i class="fas fa-file"></i> Ver</b-button>
                </b-col>
                <b-col md="2">
                  <b-link class="btn form-control btn-primary" :to="{ path: '/sepelio/listar' }" append >REGRESAR</b-link>
                </b-col>
                
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <ModalClients />
    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
    <ModalContribution />
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
// components
import ModalClients from './../components/ModalClient'
import LoadingComponent from './../pages/Loading'
import ModalContribution from './components/ModalContribution'
import ContributionDetail from './components/ContributionDetail'
export default {
  name: "UsuarioAdd",
  props: ["id_burial"],
  components:{
      vSelect,
      ModalClients,
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
      ModalContribution,
      ContributionDetail,
  },
  data() {
    return {
      isLoading: false,
      module: 'Burial',
      role: 2,
      burial: {
          id_burial:'',
          id_partner:'',
          id_user:'',
          id_check:'',
          id_partner_family:'',
          number:'',
          file:'',
          change_file:'',
          application_date:moment(new Date()).local().format("YYYY-MM-DD"),
          disbursement_date:moment(new Date()).local().format("YYYY-MM-DD"),
          observation:'',
          beneficiary_type:'',
          burial_type:'01',
          total:'2200.00',
          state:1,
      },
      file:null,
      partners: [],
      partner:null,
      partners_family: [],
      partner_family: null,
      checks: [],
      check:null,
      burial_type: [
        {value :"01", text :'Socio Fallecido'},
        {value :"02", text :'Familiar Directo'},
      ],
      state:[
        {value:'1',text:'Activo'},
        {value:'0',text:'Inactivo'},
      ],
      errors: {
        id_partner: false,
        id_partner_family: false,
        number: false,
        file: false,
        application_date: false,
        total: false,
        id_check: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ViewBurial();
  },
  methods: {
    GetPartnerFamily,
    ViewBurial,
    ModalContributionShow,
    EditBurial,
    Validate,
    SearchClients,
    modalClients,
    onFileChange,
    BurialType,
    SearchChecks,
    ValidateAcceptBurial,
    AcceptBurial,
    ValidateCancelBurial,
    CancelBurial,
    OpenFile,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function OpenFile() {
  let me = this;
  let url = me.url_base + this.burial.file;
  window.open(url,'_blank');

}


function ValidateAcceptBurial() {
  this.errors.id_partner = this.partner == null ? true : false;
  this.errors.id_partner_family = this.partner_family == null ? true : false;
  this.errors.id_check = this.check == null ? true : false;
  this.errors.number = this.burial.number.length == 0 ? true : false;
  this.errors.application_date = this.burial.application_date.length == 0 ? true : false;
  this.errors.disbursement_date = this.burial.disbursement_date.length == 0 ? true : false;
  this.errors.total = parseFloat(this.burial.total) == 0 ? true : false;

  if (this.errors.id_partner) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.id_partner_family) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.id_check) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.number) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.application_date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.disbursement_date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.total) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }

  let me = this;
  Swal.fire({
    title: "Esta seguro de aceptar la solicitud de sepelio ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.AcceptBurial();
    }
  });
}

function AcceptBurial() {
  let me = this;
  let url = me.url_base + "burial/accept-burial";
  let data = new FormData();
  data.append("id_burial", me.burial.id_burial);
  data.append("id_partner", me.partner.id);
  data.append("id_user", me.user.id_user);
  data.append("id_check", me.check.id);
  data.append("number", me.burial.number);
  data.append("id_partner_family", me.partner_family.value);
  data.append("file", me.burial.change_file);
  data.append("application_date", me.burial.application_date);
  data.append("disbursement_date", me.burial.disbursement_date);
  data.append("observation", me.burial.observation);
  data.append("beneficiary_type", me.partner_family.type_beneficiary);
  data.append("burial_type", me.burial.burial_type);
  data.append("total", me.burial.total);
  data.append("state", 2);
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.burial.file = response.data.result.file;
        me.burial.state = response.data.result.state;
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function ValidateCancelBurial() {
  this.errors.id_partner = this.partner == null ? true : false;
  this.errors.id_partner_family = this.partner_family == null ? true : false;
  this.errors.number = this.burial.number.length == 0 ? true : false;
  this.errors.application_date = this.burial.application_date.length == 0 ? true : false;
  this.errors.disbursement_date = this.burial.disbursement_date.length == 0 ? true : false;
  this.errors.total = parseFloat(this.burial.total) == 0 ? true : false;

  if (this.errors.id_partner) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.id_partner_family) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.number) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.application_date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.disbursement_date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.total) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }

  let me = this;
  Swal.fire({
    title: "Esta seguro de rechazar la solicitud de sepelio ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.CancelBurial();
    }
  });
}

function CancelBurial() {
  let me = this;
  let url = me.url_base + "burial/cancel-burial";
  let data = new FormData();
  data.append("id_burial", me.burial.id_burial);
  data.append("id_partner", me.partner.id);
  data.append("id_user", me.user.id_user);
  data.append("number", me.burial.number);
  data.append("id_partner_family", me.partner_family.value);
  data.append("file", me.burial.change_file);
  data.append("application_date", me.burial.application_date);
  data.append("disbursement_date", me.burial.disbursement_date);
  data.append("observation", me.burial.observation);
  data.append("beneficiary_type", me.partner_family.type_beneficiary);
  data.append("burial_type", me.burial.burial_type);
  data.append("total", me.burial.total);
  data.append("state", 0);
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.burial.state = response.data.result.state;
        me.burial.file = response.data.result.file;
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function BurialType() {
  if (this.burial.burial_type == "01") {
    this.burial.total = "2200.00";
  }else if(this.burial.burial_type == "02"){
    this.burial.total = "1100.00";
  }else{
    this.burial.total = "0.00";
  }
}

function SearchChecks(search, loading) {
  
   let me = this;
    let url = this.url_base + "search-checks/" + this.burial.id_partner+"/1";
    if (search !== "") {
      loading(true);
      axios({
        method: "GET",
        url: url,
      }).then(function (response) {
            me.checks = response.data;
            loading(false);
      })
    }
}

function GetPartnerFamily() {
  let me = this;
  if (this.partner != null) {
    let id_partner = this.partner.id;
    let url = me.url_base + "search-partner-family/"+id_partner;
    axios({
      method: "GET",
      url: url,
      headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role, },
    })
    .then(function (response) {
      if (response.data.status == 200) {
      me.partners_family = response.data.result;
    }else{
      me.partners_family = [];
    }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
  }else{
    me.partners_family = [];
    me.partner_family = null;
  }
  
  
}

function ViewBurial() {

  let id_burial = je.decrypt(this.id_burial);
  let me = this;
  let url = me.url_base + "burial/view/"+id_burial;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role:me.role },
  })
  .then(function (response) {
    if (response.data.status == 200) {
          me.burial.id_burial = response.data.result.id_burial;
          me.burial.id_partner = response.data.result.id_partner;
          me.burial.id_user = response.data.result.id_user;
          me.burial.id_check = response.data.result.id_check;
          me.burial.id_partner_family = response.data.result.id_partner_family;
          me.burial.number = response.data.result.number;
          me.burial.file = response.data.result.file;
          me.burial.application_date = response.data.result.application_date;
          me.burial.disbursement_date = response.data.result.disbursement_date;
          me.burial.observation = response.data.result.observation;
          me.burial.beneficiary_type = response.data.result.beneficiary_type;
          me.burial.burial_type = response.data.result.burial_type;
          me.burial.total = response.data.result.total;
          me.burial.state = response.data.result.state;
          me.partner = {id:response.data.result.id_partner, full_name: response.data.result.partner_name + " - "+response.data.result.partner_document_number }
          me.partner_family = {value:response.data.result.id_partner_family, text: response.data.result.partner_family_name + " - "+response.data.result.partner_family_document_number,type_beneficiary: response.data.result.beneficiary_type }
          me.check = {id:response.data.result.id_check, full_name: response.data.result.check }

    }else{
      me.burial.number = '';
    }
  })
  .catch((error) => {
    console.log(error);
    Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    me.isLoading = true;
  });
}


function onFileChange(e) {
  this.burial.change_file = e.target.files[0];
}

function ModalContributionShow() {
  if (this.partner != null) {
    EventBus.$emit('ModalContributionShow',this.partner.id, this.role);
  }
}

function modalClients() {
  EventBus.$emit('ModalClientsShow');
}

function SearchClients(search, loading) {
  
   let me = this;
    let url = this.url_base + "search-partner/" + search;
    if (search !== "") {
      loading(true);
      axios({
        method: "GET",
        url: url,
      }).then(function (response) {
            me.partners = response.data;
            loading(false);
      })
    }
}


function EditBurial() {
  let me = this;
  let url = me.url_base + "burial/edit";
 let data = new FormData();
  data.append("id_burial", me.burial.id_burial);
  data.append("id_partner", me.partner.id);
  data.append("id_user", me.user.id_user);
  data.append("number", me.burial.number);
  data.append("id_partner_family", me.partner_family.value);
  data.append("file", me.burial.change_file);
  data.append("application_date", me.burial.application_date);
  data.append("disbursement_date", me.burial.disbursement_date);
  data.append("observation", me.burial.observation);
  data.append("beneficiary_type", me.partner_family.type_beneficiary);
  data.append("burial_type", me.burial.burial_type);
  data.append("total", me.burial.total);
  data.append("state", me.burial.state);
 
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {
  this.errors.id_partner = this.partner == null ? true : false;
  this.errors.id_partner_family = this.partner_family == null ? true : false;
  this.errors.number = this.burial.number.length == 0 ? true : false;
  this.errors.application_date = this.burial.application_date.length == 0 ? true : false;
  this.errors.disbursement_date = this.burial.disbursement_date.length == 0 ? true : false;
  this.errors.total = parseFloat(this.burial.total) == 0 ? true : false;

  if (this.errors.id_partner) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.id_partner_family) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.number) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.application_date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.disbursement_date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.total) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }

  let me = this;
  Swal.fire({
    title: "Esta seguro de modificar el sepelio?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.EditBurial();
    }
  });
}
</script>
